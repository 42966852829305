
require('normalize.css/normalize.css');
require('./styles/index.scss');
const isWebview = require('is-ua-webview');
const qs = require('qs');
const lottie = require('lottie-web');

import Swiper, { Mousewheel, EffectCoverflow } from 'swiper'; 
Swiper.use([Mousewheel, EffectCoverflow]);
import 'swiper/swiper-bundle.css';


if( window.location.hostname !== 'localhost' && 
  ( !isWebview( navigator.userAgent ) || 
    (document.referrer.indexOf('//m.facebook.com/') === -1 && document.referrer.indexOf('//www.messenger.com/common/referer_frame.php') === -1 && document.referrer !== '' && document.referrer !== window.location.href )
  )
 ) {
  // document.body.innerHTML = document.referrer;
  // window.location = "https://m.me/1763747803723778";
}

(function() {
  let selectedValues = [0, 0];
  let selectedWords = ['', ''];

  window.addEventListener("load", () => {
    document.body.className += ' loaded';

    initDragging();
    initAnimations();
    initTutorial();
  });
  window.extAsyncInit = function() {
    // the Messenger Extensions JS SDK is done loading 
    // console.log(MessengerExtensions)
  };

  /* */


  Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
  };

  let animation = null;
  const initAnimations = () => {
    animation = lottie.loadAnimation({
      container: document.getElementById('anim-happy'), 
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData: require('./assets/wave-anim.json')
    });
  }
  const playAnimation = () => {
    if( animation ) {
      animation.play();
    }
  }

  const initTutorial = () => {

    const q = qs.parse( window.location.search, { ignoreQueryPrefix: true } );
    const hasGeneratedSong = q.hasGeneratedSong;
    if( hasGeneratedSong === '1' ) {
      playAnimation();
      return;
    }

    let tutStep = 0;
    const bodyCL = document.body.classList;
    const classPre = 'tut--';

    bodyCL.add(`${classPre}active`);
    const nextStep = () => {
      bodyCL.remove(`${classPre}${tutStep}`)
      if( tutStep === 4 ) {
        bodyCL.remove(`${classPre}active`);
        playAnimation();
      }
      else {
        bodyCL.add(`${classPre}${++tutStep}`);
        setTimeout(nextStep, 2000);
      }
    }
    nextStep();

    document.querySelector('[data-tutorial-overlay]').addEventListener('click', nextStep);
  }

  const initDragging = () => {
   
    document.querySelector('[data-accept]').addEventListener('click', () => {
      sendAndClose();
    })

    document.querySelectorAll('[data-word-selector]').forEach( (selector, sideIndex) => {
      const wordsElems = selector.querySelectorAll('[data-word]');
      const words = [].slice.call(wordsElems).map( elem => elem.getAttribute('data-word') );

      const swiper = new Swiper(selector, {
        direction: 'vertical',
        loop: true,
        speed: 200,
        slidesPerView: 'auto',
        watchSlidesVisibility: true,
        centeredSlides: true,
        mousewheel: {
          forceToAxis: true,
          sensitivity: 0.8,
        },
        slideClass: 'pill',
        freeMode: true,
        freeModeSticky: true,
        freeModeMomentumBounce: false,
        freeModeMomentumRatio: 1,
        freeModeMomentumVelocityRatio: 0.5,
        loopAdditionalSlides: 8,
        loopedSlides: 8,

        effect: 'coverflow',
        coverflowEffect: {
          rotate: 30,
          stretch: 0,
          depth: 250,
          modifier: 1,
          slideShadows: false,
        },
        on: {
          slideChange: (e) => {
            selectedValues[ sideIndex ] = e.realIndex;
            selectedWords[ sideIndex ] = words[ e.realIndex ];
          },
          slideChangeTransitionEnd: (e) => {
            // workaround for loop bug
            if( e.isBeginning ) {
              e.slideTo(words.length, 0);
            }
            else if( e.isEnd ) {
              e.slideTo(-1, 0);
            }
          }
        }
      });

      document.querySelector('[data-word-randomizer]').addEventListener('click', e => {
        const rand = Math.floor( Math.random() * 6 );
        const t = e.currentTarget;
        t.setAttribute('disabled', 'disabled');
        setTimeout( () => {
          t.removeAttribute('disabled');
        }, 200);

        swiper.slideTo( rand );
      })
    });

  }

  const sendAndClose = () => {
    if( typeof MessengerExtensions !== 'undefined' ) {
      const but = document.querySelector('[data-accept]');

      // firstTime
      const q = qs.parse( window.location.search, { ignoreQueryPrefix: true } );
      const url = `https://api.chatfuel.com/bots/${q.botId}/users/${q.userId}/send?chatfuel_token=${q.token}&chatfuel_block_name=${q.respBlock}&word_1=${selectedWords[0]}&word_2=${selectedWords[1]}`;

      but.setAttribute('disabled', 'disabled');
      window.fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function(response) {
          but.removeAttribute('disabled');
          MessengerExtensions.requestCloseBrowser(() => {}, () => {});
          // return response.json()
        }).then(function(json) {
          console.log('parsed json', json)
        }).catch(function(ex) {
          console.log('parsing failed', ex)
        })
      ;
    }
    else {
      // ??
    }
  }
}());